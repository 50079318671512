import * as React from 'react';
import * as dateFns from 'date-fns';
import { Project, newProject } from '../../classes';
import { ContentTrigger, Icon } from '../../components';
import { IProject } from '../../interfaces';
import { scorService } from '../../services';
import { projectStore as store, routerStore } from '../../stores';

interface IState {
  loading: boolean;
  projects: IProject[];
}

export class UserProjects extends React.Component {
  state: IState = {
    loading: true,
    projects: [],
  };

  componentDidMount() {
    scorService
      .projects()
      .then(projects => this.setState({ projects }))
      .catch(err => console.log(err))
      .then(() => this.setState({ loading: false }));
  }

  getProject(id: string) {
    scorService
      .show(id)
      .then(project => { console.log(JSON.stringify(project)); this.setProject(project);})
      .catch(err => console.log(err));
  }

  setProject(project: IProject): void {
    store.project = new Project(project);
    routerStore.redirect('/project');
  }

  newFrom(action: string): void {
    switch (action) {
      case 'template':
        scorService
          .newProjectFromTemplate('Test')
          .then(project => this.setProject(project))
          .catch(err => console.log(err));
        break;

      case 'scratch':
        this.setProject(newProject('Test'));
        break;
    }
  }

  render() {
    const { loading, projects } = this.state;

    return (
      <main className="flex-down align-start justify-start h-100vh">
        <div className="navbar flex align-center justify-center full-width p-1p5">
          <h4>Projects</h4>
          <div className="btn-create-project">
            <ContentTrigger
              type="dropdown"
              buttonClassName="dropdown-trigger button--medium flex align-center justify-between ml-3"
              buttonContent={
                <>
                  <p>New project</p>
                  <Icon
                    className="hw-22 ml-0p25"
                    name="chevron-down"
                  />
                </>
              }
              content={
                <div className="dropdown dropdown--small w-200">
                  <button onClick={() => this.newFrom('template')}>
                    From template
                  </button>
                  <button onClick={() => this.newFrom('scratch')}>
                    From scratch
                  </button>
                </div>
              }
            />
          </div>
        </div>
        <hr />
        <div className="flex flex-1 align-start justify-center full-width p-1p5">
          {loading && <p>Loading...</p>}
          <div className="flex-down align-start justify-start w-250">
            {projects.map((project, i) => (
              <button
                key={i}
                className={`button flex align-center justify-between full-width ${i !== 0 ? 'mt-1' : ''}`}
                onClick={() => this.getProject(project.id)}
              >
                <b>{project.name}</b>
                <p>{dateFns.format(new Date(project.created_at), 'DD/MM/YY')}</p>
              </button>
            ))}
          </div>
        </div>
      </main>
    );
  }
}
