import { computed, observable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { IProcess, IProcessData } from '../interfaces';
import { Model } from './Model';

export interface KeyProcess {
  [key: string]: Process;
}

export class Process {
  id: string;
  @observable model_id: string|null;
  @observable parent_id: string|null;
  @observable prev_id: string|null;
  @observable next_id: string|null;
  @observable prev_sibling_id: string|null;
  @observable next_sibling_id: string|null;
  @observable name: string;
  @observable description: string;
  @observable data: IProcessData[];
  model: (() => Model);

  constructor(process: IProcess, model: Model) {
    this.id = process.id;
    this.model_id = process.model_id;
    this.parent_id = process.parent_id;
    this.prev_id = process.prev_id;
    this.next_id = process.next_id;
    this.prev_sibling_id = process.prev_sibling_id;
    this.next_sibling_id = process.next_sibling_id;
    this.name = process.name;
    this.description = process.description;
    this.data = JSON.parse(process.data);
    this.model = () => model;
  }

  @computed
  get hasPreviousProcess(): boolean {
    return this.prev_id !== null;
  }

  @computed
  get hasNextProcess(): boolean {
    return this.next_id !== null;
  }

  @computed
  get displayName(): string {
    return `${this.identifier} - ${this.name}`;
  }

  @computed
  get identifier(): string {
    if (this.prevProcess) {
      if (this.parent_id === this.prev_id) {
        return `${this.prevProcess.identifier}.1`;
      } else if (this.parent_id !== null && this.prevProcess.parent_id === this.parent_id) {
        const array: string[] = this.prevProcess.identifier.split('.');
        const lastElement = (array.pop() as string);
        return `${array.join('.')}.${parseInt(lastElement, 10) + 1}`;
      } else if (this.prev_sibling_id) {
        // @TODO Create function for this, as its used everywhere
        const prevSibling = this.model().processes[this.prev_sibling_id];
        const array: string[] = prevSibling.identifier.split('.');
        const lastElement = (array.pop() as string);
        if (lastElement && array.length > 0) {
          return `${array.join('.')}.${parseInt(lastElement, 10) + 1}`;
        }
        return `${parseInt(prevSibling.identifier, 10) + 1}`;
      } else {
        console.log('Would this ever happen?'); // @TODO Keep console open to check. If not, remove...
      }
    }

    // Process must be root process
    return '1';
  }

  @computed
  get prevProcess(): Process|null {
    const model = this.model();
    if (this.prev_id && model && this.prev_id in model.processes) {
      return model.processes[this.prev_id];
    }
    return null;
  }

  @computed
  get lastChild(): Process|null {
    if (!this.next_id) {
      return null;
    }

    const model = this.model();
    let process = model.processes[this.next_id];

    if (process.parent_id !== this.id) {
      return null;
    }

    while (process.next_id) {
      let nextProcess = model.processes[process.next_id]
      if (nextProcess.parent_id === this.id) {
        process = nextProcess;
      } else {
        break;
      }
    }

    return process;
  }
}

export const newProcess = (model: Model, name: string, description: string, parent_id: string|null = null, prev_id: string|null = null, next_id: string|null = null, prev_sibling_id: string|null = null, next_sibling_id: string|null = null): Process => {
  const process: IProcess = {
    id: uuid(),
    model_id: model.id,
    parent_id,
    next_id,
    prev_id,
    prev_sibling_id,
    next_sibling_id,
    name,
    description,
    data: '[]',
  };

  return new Process(process, model);
}
