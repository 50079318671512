import { IRoute } from '../interfaces';
import * as Views from '../views/shared';

export const sharedRoutes: IRoute[] = [
  {
    path: '/',
    component: Views.Home,
    exact: true,
  },
];
