import * as React from 'react';
import { observer } from 'mobx-react';
import { Model, Process, Project } from '../../classes';
import { ILabelVal, ProjectActions } from '../../interfaces';
import { projectStore } from '../../stores';
import { SelectInput } from '../form';
import { Button } from './button.component';
import { ContentTrigger } from './content-trigger.component';
import { Icon } from './icon.component';
import { ProcessData } from './process-data.component';

interface IProps {
  name: string;
  model: Model;
}

@observer
export class ModelWrapper extends React.Component<IProps> {
  get processOptions(): ILabelVal[] {
    const { model } = this.props;
    const options: ILabelVal[] = [];
    let process = model.rootProcess;

    if (!process) { return []; } // If no root process exists, return

    // Sort processess from root to last
    while (process) {
      options.push({
        label: process.displayName,
        value: process.id,
      });

      process = process.hasNextProcess
        ? model.processes[(process.next_id as string)]
        : null;
    }

    return options;
  }

  render() {
    const { model } = this.props;
    const { currentProcess: process } = model;

    return (
      <div className="model-wrapper flex-down align-start justify-start full-width p-1p5">
        <h1>{model.name} Model</h1>
        {process && (
          <div className="flex align-start justify-start full-width mt-1">
            <Button
              className="btn-change-process--first"
              disabled={!process.hasPreviousProcess}
              onClick={() => model.currentProcessId = process.prev_id}
            >
              <Icon name="chevron-left" />
            </Button>
            <SelectInput
              label="Process"
              icon="chevron-down"
              value={process.id}
              options={this.processOptions}
              setValue={(key: string, id: string) => model.currentProcessId = id}
              padProcesses
            />
            <Button
              className="btn-change-process--last"
              disabled={!process.hasNextProcess}
              onClick={() => model.currentProcessId = process.next_id}
            >
              <Icon name="chevron-right" />
            </Button>
          </div>
        )}
        <div className="flex align-start justify-between full-width mt-2">
          {process
            ? (
              <div className="flex-down align-start justify-start full-width">
                <h3>{process.displayName}</h3>
                <h4 className="mt-1">Description</h4>
                <p className="mt-0p5">{process.description || 'No description.'}</p>
              </div>
            )
            : <h3>No processes</h3>
          }
          {/* @TODO Create dropdown component that uses dropdown trigger, wraps all the onClicks with a preventEvents and hides the dropdown after onClick */}
          <div className="dropdown-wrapper">
            <ContentTrigger
              type="dropdown"
              buttonClassName="dropdown-trigger button flex align-center justify-between ml-3"
              buttonContent={
                <>
                  <p>Action</p>
                  <Icon
                    className="hw-22 ml-0p25"
                    name="chevron-down"
                  />
                </>
              }
              content={
                <div className="dropdown w-200">
                  {model.currentProcessId && (
                    <>
                      {[ProjectActions.BEFORE, ProjectActions.AFTER, ProjectActions.CHILD].map(action => (
                        <button
                          key={action}
                          onClick={() => projectStore.createActionObject(action, model)}
                        >
                          Insert {action}
                        </button>
                      ))}
                      <button onClick={() => projectStore.createActionObject(ProjectActions.UPDATE, model)}>
                        Update process
                      </button>
                    </>
                  )}
                </div>
              }
            />
          </div>
        </div>

        {process && process.data.map(data => (
          <ProcessData
            key={data.label}
            {...data}
          />))}
      </div>
    );
  }
}
