import { Model } from '../classes';

/**
 * Interfaces
 */
export interface IProject {
  id: string;
  name: string;
  as_is: IModel;
  to_be: IModel;
  created_at: string;
}

export interface IModel {
  id: string;
  name: string;
  type: ModelTypes;
  processes: IProcess[];
}

export interface IKeyProcess {
  [key: string]: IProcess;
}

export interface IProcess {
  id: string;
  model_id: string|null;
  parent_id: string|null;
  next_id: string|null;
  prev_id: string|null;
  next_sibling_id: string|null;
  prev_sibling_id: string|null;
  name: string;
  description: string;
  data: string;
  // model: () => Model;
}

export interface IProcessData {
  label: string;
  values: string[];
}

export interface IProjectActionObject {
  actionType: ProjectActions;
  modelType: ModelTypes;
  identifier: string;
  name: string;
  description: string;
  data: IProcessData[];
}

/**
 * Enums
 */
export enum ModelTypes {
  AS_IS = 'as_is',
  TO_BE = 'to_be',
}

export enum ProjectActions {
  NEW = 'new',
  AFTER = 'after',
  BEFORE = 'before',
  CHILD = 'child',
  UPDATE = 'update',
  DELETE = 'delete',
}
