import { IRoute } from '../interfaces';
import * as Views from '../views/user';

export const userRoutes: IRoute[] = [
  {
    path: '/project',
    component: Views.UserProject,
    exact: true,
  },
  {
    path: '/project/action',
    component: Views.UserProjectAction,
    exact: true,
  },
  {
    path: '/projects',
    component: Views.UserProjects,
    exact: true,
  },
];
