import * as React from 'react';
import { IFormFieldProps } from '../../interfaces';
import { showTopLabel, preventEvents } from '../../utils';
import { Icon } from '../shared';

interface ISelectProps extends IFormFieldProps {
  padProcesses?: boolean;
}

interface IState {
  showDropdown: boolean;
}

// @TODO Make dropdown disappear if any other element is clicked

export class SelectInput extends React.Component<ISelectProps> {
  state: IState = {
    showDropdown: false,
  };

  componentDidMount() {
    document.body.addEventListener('click', (e: any) => {
      if (this.state.showDropdown) {
        const selectOptions = document.getElementsByClassName('select-options')[0];
        if (e.target.contains(selectOptions)) {
          this.setState({ showDropdown: false });
        }
      }
    })
  }

  toggleDropdown() {
    this.setState({ showDropdown: !this.state.showDropdown });
  }

  getLabelForValue(props: IFormFieldProps): string|number {
    if (props.options) {
      for (const option of props.options) {
        if (option.value === props.value) {
          return option.label;
        }
      }
    }
    return 'N/A';
  }

  paddingForIdentifier(text: string): string {
    const basePadding: number = 0.9;
    const identifier: string = text.split('-')[0].trim();
    const levelArray = identifier.split('.');
    return `${basePadding + (0.45 * levelArray.length)}rem`;
  }

  render() {
    const props = this.props;

    return (
      <div className={`select-input-wrapper ${props.className ? props.className : ''}`}>
        <button
          className="select-input flex align-center justify-between"
          onClick={(e: React.MouseEvent) => {
            preventEvents(e);
            this.toggleDropdown();
          }}
        >
          <p className={showTopLabel(props) ? 'dropped' : ''}>{this.getLabelForValue(props)}</p>
          {props.label && <span className={`dynamic-label${showTopLabel(props) ? '--top' : ''}`}>{props.label}</span>}
          {props.icon && <Icon className="hw-25" name={props.icon} />}
        </button>
        {this.state.showDropdown && (
          <div className="select-options flex-down align-start justify-start">
            {props.options && props.options.map(option => {
              props.padProcesses && this.paddingForIdentifier(option.label);
              return (
                <span
                  key={option.value}
                  onClick={(e: React.MouseEvent) => {
                    preventEvents(e);
                    props.setValue && props.setValue(props.name, option.value);
                    this.toggleDropdown();
                  }}
                  style={{ paddingLeft: this.paddingForIdentifier(option.label) }}
                >
                  {option.label}
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
