import * as React from 'react';
import { FormData, IFormControl } from '../../interfaces';
import { RouteComponentProps } from 'react-router';

export interface FormConsumerState {
  formData: FormData;
  submitted: boolean;
  [others: string]: any;
}

export abstract class FormConsumer<RouterParams = null, Props = null> extends React.Component<Props & RouteComponentProps<RouterParams>> {
  state: FormConsumerState = {
    formData: {},
    submitted: false,
  };

  protected apiErrors: Array<string> = [];
  protected abstract formFields: IFormControl[];
  protected abstract onSubmit(): void;

  retrieveFormData(formData: FormData): void {
    this.setState(
      {
        formData,
        submitted: true,
      },
      () => this.onSubmit(),
    );
  }

  // @IMPROVEMENT I'm thinking apiErrors should really be a state object. Forcing updates aren't best practice.
  flushApiErrors(): void {
    this.apiErrors = [];
    this.forceUpdate();
  }
}
