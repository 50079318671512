import * as React from 'react';

interface IProps {
  children: string|JSX.Element
  className?: string;
  disabled?: boolean;
  onClick?: Function;
}

export const Button = (props: IProps) => {
  const {
    children,
    className,
    disabled,
    onClick,
  } = props;

  const buttonProps: object = {
    className: className
      ? className
      : null,
    onClick: onClick && !disabled
      ? onClick
      : null,
  };

  return (
    <>
      {!disabled && (
        <button {...buttonProps}>
          {children}
        </button>
      )}
      {disabled && (
        <span className={`${className} muted`}>
          {children}
        </span>
      )}
    </>
  );
}
