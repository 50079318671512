import * as React from 'react';
import { IKeyValString } from '../../interfaces';

// @TODO Use or remove
const images: IKeyValString = {
  // 'background': require('../../../assets/images/background.jpg'),
};

interface IProps {
  className?: string;
  name: string;
}

export const Image = (props: IProps) => (
  <img
    className={props.className ? props.className : ''}
    src={images[props.name]}
    alt={props.name}
  />
);
