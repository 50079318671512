import * as dateFns from 'date-fns';
import { observable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { IProject, ModelTypes } from '../interfaces';
import { Model, newModel } from './Model';

export class Project {
  id: string;
  @observable name: string;
  created_at: string;
  @observable as_is: Model;
  @observable to_be: Model;

  constructor(project: IProject) {
    this.id = project.id;
    this.name = project.name;
    this.created_at = project.created_at;
    this.as_is = new Model(project.as_is);
    this.to_be = new Model(project.to_be);
  }
}

export const newProject = (name: string) => {
  return {
    id: uuid(),
    name,
    created_at: dateFns.format(new Date()),
    as_is: newModel('As Is', ModelTypes.AS_IS),
    to_be: newModel('To Be', ModelTypes.TO_BE),
  };
}
