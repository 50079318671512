export const getWholeNumber = (number: number) => parseInt(number.toString(), 10);

export const getDecimalPoint = (number: number) => {
  const float = number.toFixed(2);
  const decimalIndex = float.indexOf('.');
  return float.substr(decimalIndex);
}

export const numberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
