import * as React from 'react';
import { projectStore, routerStore } from '../../stores';
import { Icon } from '../shared';

interface IProps {
  backButtonPath: string;
}

export class ProjectNavbar extends React.Component<IProps> {
  render() {
    const { project } = projectStore;
    const { backButtonPath } = this.props;

    return (
      <div className="navbar flex align-center justify-center full-width p-1p5">
        <button
          className="back-wrapper flex align-center justify-center"
          onClick={() => routerStore.redirect(backButtonPath)}
        >
          <Icon
            className="hw-24"
            name="chevron-left"
          />
          <p>Back</p>
        </button>
        <h4>{project.name}</h4>
      </div>
    );
  }
}
