import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import { ProjectNavbar, TextInput, Button, ContentTrigger, Icon } from '../../components';
import { IProjectActionObject, ProjectActions } from '../../interfaces';
import { projectStore, routerStore } from '../../stores';

@observer
export class UserProjectAction extends React.Component<RouteComponentProps> {
  get actionType() {
    return (projectStore.action as IProjectActionObject).actionType;
  }

  get title(): string {
    switch (this.actionType) {
      case ProjectActions.UPDATE:
        return 'Update process';

      default:
        return `Insert ${this.actionType}`;
    }
  }

  get buttonTextPrefix(): string {
    return this.actionType === ProjectActions.UPDATE
      ? 'Update'
      : 'Save';
  }

  get dataOptions(): string[] {
    return ['Cost', 'Resources', 'Software', 'Hardware'].map(option => {
      return (projectStore.action as IProjectActionObject).data.find(e => e.label === option)
        ? ''
        : option;
    }).filter(e => e);
  }

  render() {
    const { action } = projectStore;

    return (
      <main className="flex-down align-center justify-start h-100vh">
        <ProjectNavbar backButtonPath="../" />
        <hr />
        {action && (
          <div className="flex-down align-start justify-start full-width p-1p5 w-450">
            <h2>{this.title}</h2>
            <h4 className="mt-2">Identifier</h4>
            <p className="mt-0p5">{action.identifier}</p>
            <h4 className="mt-2">Name</h4>
            <TextInput
              className="mt-0p5"
              label="Name"
              value={action.name}
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) => {
                  action.name = e.target.value;
                  projectStore.action = action;
                }
              }
            />
            <h4 className="mt-2">Description</h4>
            <TextInput
              className="mt-0p5"
              label="Description"
              value={action.description}
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) => {
                  action.description = e.target.value;
                  projectStore.action = action;
                }
              }
            />
            <div className="dropdown-wrapper flex align-center justify-between full-width mt-2">
              <h3>Data</h3>
              {this.dataOptions.length > 0 && (
                <ContentTrigger
                  type="dropdown"
                  buttonClassName="dropdown-trigger button--medium flex align-center justify-between ml-3"
                  buttonContent={
                    <>
                      <p>Add data</p>
                      <Icon
                        className="hw-22 ml-0p25"
                        name="chevron-down"
                      />
                    </>
                  }
                  content={
                    <div className="dropdown dropdown--small w-200">
                      {this.dataOptions.map(label => (
                        <button
                          key={label}
                          onClick={() => action.data.push({ label, values: [''] })
                          }>
                          {label}
                        </button>
                      ))}
                    </div>
                  }
                />
              )}
            </div>
            {action.data.map((data, i) => (
              <React.Fragment key={i}>
                <div className="flex align-center justify-between full-width mt-2">
                  <h4>{data.label}</h4>
                  <div className="flex align-center justify-between remove-right-borders">
                    <button
                      className="button--small"
                      onClick={() => action.data[i].values.push('')}
                    >
                      <Icon className="hw-18" name="plus" />
                    </button>
                    <button
                      className="button--small"
                      onClick={() => action.data.splice(i, 1)}
                    >
                      <Icon className="hw-18" name="close" />
                    </button>
                  </div>
                </div>
                {data.values.map((value, j) => (
                  <div
                    key={j}
                    className="flex align-stretch justify-center full-width remove-right-borders mt-0p5"
                  >
                    <TextInput
                      key={j}
                      value={value}
                      label={data.label}
                      onChange={(e: any) => action.data[i].values[j] = e.target.value}
                    />
                    <button
                      className="button--small"
                      onClick={() => action.data[i].values.splice(j, 1)}
                    >
                      <Icon className="hw-18" name="close" />
                    </button>
                  </div>
                ))}
                {data.values.length === 0 && <p className="mt-1">Click the plus icon to add fields.</p>}
              </React.Fragment>
            ))}
            {action.data.length === 0 && <p className="mt-1">No data fields.</p>}
            <Button
              className="button mt-2"
              onClick={
                () => {
                  projectStore
                        .getModelForType(action.modelType)
                        .createOrUpdateProcessForAction(action);
                  routerStore.redirect('..');
                }
              }
            >
              <>{this.buttonTextPrefix} process</>
            </Button>
          </div>
        )}
      </main>
    );
  }
}
