import { History } from 'history';
import { action, observable} from 'mobx';
import { CONFIG } from '../config';
import { session } from '../services';

class RouterStore {
  @observable loggedIn: boolean = session.has('access_token');
  history: History = CONFIG.history;

  @action
  redirect(uri: string) {
    this.history.push(uri);
  }

  @action
  login() {
    this.loggedIn = true;
    this.history.push(`${CONFIG.loginPaths[CONFIG.scopedPath()]}`);
  }

  @action
  logout() {
    this.loggedIn = false;
    // @TODO Destroy session
    // authService
    //   .logout()
    //   .then(() => {
    //     session.destroy(CONFIG.sessionKey());
    //     this.history.push(`${CONFIG.logoutPaths[CONFIG.scopedPath()]}`);
    //   });
  }
}

export const routerStore = new RouterStore();
