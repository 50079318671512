import * as React from 'react';
import { IFormFieldProps } from '../../interfaces';
import { fieldHasErrors, formErrors } from '../../utils';

export const CheckboxInput = (props: IFormFieldProps) => (
  <>
    <label className={`checkbox flex align-center justify-start ${props.className ? props.className : ''}`}>
      <input
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
      />
      {props.text}
    </label>
    {fieldHasErrors(props) && formErrors(props)}
  </>
);
