import * as React from 'react';
import { IKeyValString } from '../../interfaces';

const icons: IKeyValString = {
  'arrow-left': require('../../../assets/icons/arrow-left.svg'),
  'arrow-right': require('../../../assets/icons/arrow-right.svg'),
  'calendar': require('../../../assets/icons/calendar.svg'),
  'card-back': require('../../../assets/icons/card-back.svg'),
  'card-front': require('../../../assets/icons/card-front.svg'),
  'checked': require('../../../assets/icons/checked.svg'),
  'chevron-down': require('../../../assets/icons/chevron-down.svg'),
  'chevron-left': require('../../../assets/icons/chevron-left.svg'),
  'chevron-right': require('../../../assets/icons/chevron-right.svg'),
  'chevron-up': require('../../../assets/icons/chevron-up.svg'),
  'close': require('../../../assets/icons/close.svg'),
  'credibot-head': require('../../../assets/icons/credibot-head.svg'),
  'draggable': require('../../../assets/icons/draggable.svg'),
  'email': require('../../../assets/icons/email.svg'),
  'info-circle': require('../../../assets/icons/info-circle.svg'),
  'loading-animate': require('../../../assets/icons/loading-animate.svg'),
  'magnify': require('../../../assets/icons/magnify.svg'),
  'minus': require('../../../assets/icons/minus.svg'),
  'mobile': require('../../../assets/icons/mobile.svg'),
  'nav-burger': require('../../../assets/icons/nav-burger.svg'),
  'nav-campaign': require('../../../assets/icons/nav-campaign.svg'),
  'nav-create-campaign': require('../../../assets/icons/nav-create-campaign.svg'),
  'nav-create-pp': require('../../../assets/icons/nav-create-pp.svg'),
  'nav-dashboard': require('../../../assets/icons/nav-dashboard.svg'),
  'nav-help': require('../../../assets/icons/nav-help.svg'),
  'nav-logout': require('../../../assets/icons/nav-logout.svg'),
  'nav-overview': require('../../../assets/icons/nav-overview.svg'),
  'nav-pay-now': require('../../../assets/icons/nav-pay-now.svg'),
  'nav-settings': require('../../../assets/icons/nav-settings.svg'),
  'network': require('../../../assets/icons/network.svg'),
  'phone': require('../../../assets/icons/phone.svg'),
  'plus': require('../../../assets/icons/plus.svg'),
  'receipt-bottom': require('../../../assets/icons/receipt-bottom.svg'),
  'triangle-down': require('../../../assets/icons/triangle-down.svg'),
  'user': require('../../../assets/icons/user.svg'),
  'view': require('../../../assets/icons/view.svg'),
};

interface IProps {
  className?: string;
  name: string;
}

export const Icon = (props: IProps) => (
  <i
    className={`flex align-center justify-center ${props.className ? props.className : ''}`}
    dangerouslySetInnerHTML={{ __html: icons[props.name] }}
  />
);
