import * as React from 'react';
import * as dateFns from 'date-fns';
import { IFormFieldProps, FormData, IKeyValAny, IFormControl } from "../interfaces";
import { session } from '../services';

/**
 * Get the values from the form data.
 *
 * @param formData FormData
 * @return {IKeyValAny}
 */
export const getValuesForFormData = (formData: FormData): IKeyValAny => {
  const object: IKeyValAny = {};
  for (const key in formData) {
    const field = formData[key];
    if (field.hasOwnProperty('value')) {
      object[key] = field.value;
    }
  }
  return object;
}

/**
 * Prevent event bubbling.
 *
 * @param e React.MouseEvent
 * @return {void}
 */
export const preventEvents = (e: Event|React.MouseEvent): void => {
  e.preventDefault();
  e.stopPropagation();
}

/**
 * Get formatted date.
 *
 * @param date Date
 * @return {string}
 */
export const formatDate = (date: Date): string => {
  if (dateFns.isToday(date)) {
    return 'Today';
  } else if (dateFns.isTomorrow(date)) {
    return 'Tomorrow';
  }
  return dateFns.format(date, 'D MMM YYYY');
}

/**
 * Check whether or not a field has a set value.
 *
 * @param val any
 * @return {string|false}
 */
export const hasValue = (val: any): string|false => val !== undefined && val;

/**
 * Determine whether or not to show label above value.
 *
 * @param props IFormFieldProps
 * @return {boolean}
 */
export const showTopLabel = (props: IFormFieldProps): boolean => {
  if (hasValue(props.value) || props.focused) {
    return true;
  }
  return false;
}

/**
 * Return form fields' placeholder.
 *
 * @param props IFormFieldProps
 * @return {string|undefined}
 */
export const getPlaceholder = (props: IFormFieldProps): string|undefined => {
  if (!hasValue(props.value) && props.focused) {
    return props.placeholder !== undefined
      ? props.placeholder
      : props.label;
  }
  return '';
}

/**
 * Check if form field has errors.
 *
 * @param field IFormFieldProps
 * @return {boolean}
 */
export const fieldHasErrors = (field: IFormFieldProps): boolean => {
  return field.touched === true
      && field.errors !== undefined
      && field.errors.map(error => !error.valid).includes(true);
}

/**
 * Display form errors.
 *
 * @param field IFormFieldProps
 * @return {JSX.Element}
 */
export const formErrors = (field: IFormFieldProps): JSX.Element => (
  <div className="form-errors">
    {field.errors !== undefined && field.errors.map(error => {
      if (!error.valid) {
        const prefix: string = 'This field';
        let postfix = '';

        switch (error.name) {
          case 'debtAmount':
            const debt = session.get('debt');
            postfix = `cannot be higher than your outstanding balance of $${debt ? debt.amount_remaining : 0}.`;
            break;

          case 'email':
            postfix = 'must be a valid email.';
            break;

          case 'exactly':
            postfix = `must have exactly ${getParameterOfDynamicValidator(field, 'exactly')} characters.`;
            break;

          case 'landline':
            postfix = 'must be a valid, australian landline number.';
            break;

          case 'matchField':
            postfix = `must match the ${getParameterOfDynamicValidator(field, 'matchField')} field.`;
            break;

          case 'max':
            postfix = `must have a maximum of ${getParameterOfDynamicValidator(field, 'max')} characters.`;
            break;

          case 'min':
            postfix = `must have a minimum of ${getParameterOfDynamicValidator(field, 'min')} characters.`;
            break;

          case 'mobile':
            postfix = 'must be a valid, australian mobile number.';
            break;

          case 'numeric':
            postfix = 'must be a valid number.';
            break;

          case 'phone':
            postfix = 'must be a valid, australian phone number.';
            break;

          case 'required':
            postfix = 'is required.';
            break;

          case 'smsName':
            postfix = 'must have a maximum of 11 characters, can only contain letters and numbers, and have a maximum of 6 numbers.';
            break;

          default:
            break;
        }

        return <p key={`${field.name}_${error.name}`}>{prefix} {postfix}</p>;
      }

      return null;
    })}
  </div>
);

/**
 * For a given validator utilizing a colon (e.g. max:255),
 * return the number of characters validating for (e.g. 255).
 *
 * @param field IFormControl|IFormFieldProps
 * @param name string
 * @return {number}
 */
export const getParameterOfDynamicValidator = (field: IFormControl|IFormFieldProps, name: string): string => {
  if (field.rules !== undefined) {
    for (const rule of field.rules) {
      if (rule.includes(name)) {
        return rule.substr(name.length + 1);
      }
    }
  }
  return '';
}
