import { action, computed, observable } from 'mobx';
import { Project, newProject, Model, Process } from '../classes';
import { project } from '../data';
import { ProjectActions, IProjectActionObject, ModelTypes } from '../interfaces';
import { getIdentifierForAction } from '../utils';
import { routerStore } from './router.store';


class ProjectStore {
  @observable project: Project = this.initialProject;
  @observable action: IProjectActionObject|null = null;

  constructor() {
    // @TODO Observe an write project to localStorage?
  }

  @computed
  get initialProject(): Project {
    return project || new Project(newProject('Project')); // @TODO Retrieve from localStorage or return new
  }

  @action
  getModelForType(modelType: ModelTypes): Model {
    return modelType === ModelTypes.AS_IS
      ? this.project.as_is
      : this.project.to_be;
  }

  // @TODO Move to utils
  createActionObject(actionType: ProjectActions, model: Model): void {
    const currentProcess = (model.currentProcess as Process);
    const prefilledData: object = {
      ...(actionType === ProjectActions.UPDATE
        ? {
          name: currentProcess.name,
          description: currentProcess.description,
          data: currentProcess.data,
        }
        : {
          name: '',
          description: '',
          data: [],
        }
      ),
    };
    this.action = ({
      actionType,
      modelType: model.type,
      identifier: getIdentifierForAction(actionType, model.currentProcess),
      ...prefilledData,
    } as IProjectActionObject);
    routerStore.redirect('/project/action');
  }
}

export const projectStore = new ProjectStore();
