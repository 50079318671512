import * as React from 'react';
import { Router as ReactRouter, Route, Switch } from 'react-router-dom';
import { CONFIG } from './ts/config';
import { IRoute } from './ts/interfaces';
import { sharedRoutes, userRoutes } from './ts/routes';
import { NotFound, Redirecter } from './ts/views';

export class Router extends React.Component {
  private routes: IRoute[] = [
    ...sharedRoutes,
    ...userRoutes,
  ];

  render() {
    return (
      <ReactRouter history={CONFIG.history}>
        <Switch>
          {this.routes.map(route => (
            <Route
              path={route.path}
              key={route.path}
              exact={route.exact}
              render={
                routerProps => (route.guard !== undefined && !route.guard())
                  ? <Redirecter to={`/${route.path.split('/')[1]}`} /> // Redirect to root path, and let scoped notFound route handle redirect
                  : (
                    <route.component
                      {...routerProps}
                      {...(route.props !== undefined
                        ? route.props
                        : {}
                      )}
                    />
                  )
              }
            />
          ))}
          <Route component={NotFound} />
        </Switch>
      </ReactRouter>
    );
  }
}
