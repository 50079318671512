import * as React from 'react';
import { ModelWrapper, ProjectNavbar } from '../../components';
import { projectStore } from '../../stores';

export class UserProject extends React.Component {
  render() {
    const { project } = projectStore;

    return (
      <main className="flex-down align-start justify-start h-100vh">
        <ProjectNavbar backButtonPath="/projects" />
        <hr />
        <div className="flex flex-1 align-start justify-center full-width">
          <ModelWrapper
            name="As Is"
            model={project.as_is}
          />
          <span className="vr" />
          <ModelWrapper
            name="To Be"
            model={project.to_be}
          />
        </div>
      </main>
    );
  }
}
