import * as React from 'react';
import { IProcessData } from '../../interfaces';

export class ProcessData extends React.Component<IProcessData> {
  render() {
    const { label, values } = this.props;

    return (
      <div className="flex-down align-start jsutify-start mt-2">
        <h2>{label}</h2>
        <ul>
          {values.map(val => (
            <li
              key={val}
              className="mt-1"
            >
              {val}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
