import { IFormControl } from '../interfaces';
import { session } from '../services';
// import { getParameterOfDynamicValidator } from '../utils';

export interface IValidator {
  [key: string]: (field: IFormControl) => boolean;
}

/**
 * Static validators.
 */
export const email = (field: IFormControl): boolean => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field.value || '');

export const debtAmount = (field: IFormControl): boolean => {
  const debt = session.get('debt');
  if (debt) {
    return parseFloat(field.value) <= debt.amount_remaining;
  }
  return false;
}

export const required = (field: IFormControl): boolean => {
  switch (field.type) {
    case 'checkbox':
      return field.checked === true;

    default:
      return ![undefined, null, false, ''].includes(field.value);
  }
}

export const numeric = (field: IFormControl): boolean => {
  return isFinite(field.value) && field.value > 0;
}

export const phone = (field: IFormControl): boolean => {
  return landline(field) || mobile(field);
}

export const landline = (field: IFormControl): boolean => {
  const phoneNumber: string = field.value.split(' ').join('');
  return /^\+?(61|0)(2|3|7|8)[0-9]{8}$/.test(phoneNumber);
}

export const mobile = (field: IFormControl): boolean => {
  const phoneNumber: string = field.value.split(' ').join('');
  return /^\+?(61|0)(4)[0-9]{8}$/.test(phoneNumber);
}

export const smsName = (field: IFormControl): boolean => {
  return /^(?!(.*\d.*){7,}$)([A-Za-z0-9]{1,11})?$/.test(field.value);
}

/**
 * Dynamic validators.
 */
// export const exactly = (field: IFormControl): boolean => {
//   const numberOfChars = parseInt(getParameterOfDynamicValidator(field, 'exactly'), 10);
//   return field.value.length === numberOfChars;
// }

// export const max = (field: IFormControl): boolean => {
//   const numberOfChars = parseInt(getParameterOfDynamicValidator(field, 'max'), 10);
//   return field.value.length <= numberOfChars;
// }

// export const min = (field: IFormControl): boolean => {
//   const numberOfChars = parseInt(getParameterOfDynamicValidator(field, 'min'), 10);
//   return field.value.length >= numberOfChars;
// }

// export const matchField = (field: IFormControl): boolean => {
//   const key: string = getParameterOfDynamicValidator(field, 'matchField')
//   if (field.parent) {
//     const fieldToMatch: IFormControl = field.parent()[key];
//     return fieldToMatch !== undefined && field.value === fieldToMatch.value;
//   }
//   return false;
// }
