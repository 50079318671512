import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { projectStore } from '../../stores';
import { Icon } from './icon.component';

export class Navbar extends React.Component<RouteComponentProps> {
  render() {
    const { project } = projectStore;

    return (
      <div className="navbar flex align-center justify-center full-width p-1p5">
        <button
          className="back-wrapper flex align-center justify-center"
          onClick={() => this.props.history.goBack()}
        >
          <Icon
            className="hw-24"
            name="chevron-left"
          />
          <p>Back</p>
        </button>
        <h4>{project.name}</h4>
      </div>
    );
  }
}
