import { createBrowserHistory, History } from 'history';
import { IKeyValString } from '../interfaces';

interface IConfig {
  appName: string;
  history: History;
  loginPaths: IKeyValString;
  logoutPaths: IKeyValString;
  scopedPath: () => string;
  sessionKey: () => string;
  sessionKeyPrefix: string;
}

const scopedPath: (() => string) = () => location.pathname.split('/')[1];
const sessionKey: (() => string) = () => `${sessionKeyPrefix}${scopedPath()}`;
const sessionKeyPrefix: string = 'scor_session_';

export const CONFIG: IConfig = {
  appName: 'SCOR',
  history: createBrowserHistory(),
  loginPaths: {
    'user': '/dashboard',
  },
  logoutPaths: {
    'user': '/login',
  },
  scopedPath,
  sessionKey,
  sessionKeyPrefix,
};
