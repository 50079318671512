import { Project } from '../classes';
import { http } from './http.service';

class ScorService {
  projects() {
    return http.get('/projects').then(res => res.data);
  }

  show(id: string) {
    return http.get(`/project/${id}`).then(res => res.data);
  }

  newProjectFromTemplate(name: string) {
    return http.post('/new-project-from-template', { name }).then(res => res.data);
  }

  storeOrUpdate(project: Project) {
    return http.post('/store-or-update', project).then(res => res.data);
  }
}

export const scorService = new ScorService();
