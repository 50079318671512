import * as React from 'react';
import { routerStore } from '../../stores';

export const Home = () => {
  routerStore.redirect('/project');

  return (
    <main className="flex align-center justify-center h-100vh">
      <h2>SCOR Model</h2>
    </main>
  );
}
